import React, { Component } from 'react';
import L from 'leaflet';
import { Map, TileLayer, LayersControl, Marker, AttributionControl } from 'react-leaflet';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import PauseIcon from '@material-ui/icons/Pause';
import moment from 'moment';
import 'moment/locale/de';
//import { translateDate } from '../../functions/translateDate';

import cssModule from './mapWolkenComponent.module.css';

const { BaseLayer } = LayersControl;

let DefaultIcon = L.icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAeCAMAAADw+3VaAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAPZQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAASWRKkwAAAFJ0Uk5TAAlmvOT6B2na/wqHAqSKZP3lFej8o0kmavt2Aam3BND1VR3hKh7bDNH0VKqzBmP3cwXrOAgL6d8O7e6AgxPsjpA18fI3kzLzNLW4NvY5W1/vdxFiIh8AAAEpSURBVHicZZFne4IwFIWvIiC4cYPiHqiooHYPu7S1y/7/P9NcQiDo+XTOmyfJHQBUsbiQEMWEEI8BJ0lOKr6SshRgNSUqgcQUO0hnlIiyacpzeS/mCwXf5DysFTGUypVqtVIuoS9qyGto67rhPanXMdXQN9A1Tfqm2cTUwMpbxLQ7rLZOm8QW6aKLpff6jPd72EQXYDA858MBwGhMjDVhfGKROB4RN8WfZjbF9gzTFO0c3WLpoHeWC0xzbzqu12VmtV6v6ERcFYILvOgcYHMRxZcbv4ZslMustqtrHt+owWJueX4X7vHeDfGDxi34MeRbfvFPzwy/vPLc3DL+5vAcdnuKrfcIBvuD8oMU5fCJe1K+vk8wOAfkgn3K4Yf8YP2eYTD04/HPYOkf2r8fMSCIuusAAAAASUVORK5CYII=',
    iconSize: [23, 30],
    iconAnchor: [12, 42]
});

L.Marker.prototype.options.icon = DefaultIcon;

class mapComponent extends Component {

    componentWillUnmount() {
        this.stopSlide(this.state.slider_value);
    }

    home_url;
    radar_layers;
    radar_folders;
    slider_min;
    stop_sliding;
    map_object;
    next_layer;
    last_layer;
    user_time;
    /*legend_type;*/

    constructor(props) {
        super(props);
        this.state = {
            lat: 54.048433,
            lng: 9.230591,
            zoom: 6,
            slider_value: 0,
            current_layer: { datetime: '', id: 0 },
            slider_max: 100,
            kartentyp: 'Radar',
            paused: false,
            stopped: false,
            mapChanged: false,
            pausedSliderValue: 0,
            sliderMoved: false,
            language: "de",
        }
        this.mapRef = React.createRef();
        this.home_url = "https://radar.qmet.de/";
        this.radar_layers = [];
        this.radar_folders = [];
        this.slider_min = 0;
        this.stop_sliding = false;
        this.map_object = null;
        this.next_layer = { datetime: '', id: 0 };
        this.last_layer = null;
        this.user_time = '';
        this.mapChanged = false;
        this.selectedFolder = '';
        this.selectedSub = '';
        this.refStopped = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        try{
        return {
            lat: nextProps.propsLon,
            lng: nextProps.propsLat,
            zoom: nextProps.propsZoom,
            kartentyp: nextProps.kartentyp,
            mapChanged: nextProps.mapChanged,
        };
    }catch(error){
        console.log('202105111235',error);
    }
    }

    async shouldComponentUpdate(nextProps) {
        try{
        if (this.state.lat !== nextProps.propsLon || this.state.lng !== nextProps.propsLat || this.state.kartentyp !== nextProps.kartentyp) {
            this.setState({ kartentyp: nextProps.kartentyp }, () => { });
            this.mapChanged = this.state.mapChanged;
            await this.Sleep(1001);
            this.mapChanged = !this.state.mapChanged;
            await this.getRadarFolder();
            this.CreateMapObject();
            await this.getRadarLayers();
            await this.setRadarLayers();
        }
        return true;
    }catch(error){
        console.log('202105111236',error);
    }
    }

    async componentDidMount() {
        try{
        this.setState({language: this.props.language.toString()});
        await this.getRadarFolder();
        this.CreateMapObject();
        await this.getRadarLayers();
        await this.setRadarLayers();
        this.user_time = moment((moment().format('YYMMDDhhmm')), 'YYMMDDhhmm').local().format('LLLL');
    }catch(error){
        console.log('202105111237',error);
    }
    }

    getRadarFolder = async () => {
        this.radar_layers = [];
        this.radar_folders = [];
        try {
            let url_radar = 'https://radar.qmet.de:8080/clouds';
            let response = await fetch(url_radar);
            let data = await response.json();
            this.radar_folders = data;
        }
        catch (message) {
            console.log('fetching Radar folder', message);
        }
    }

    CreateMapObject = () => {
        try{
        this.map_object = this.mapRef.current.leafletElement;
        this.map_object.dragging.enable();
    }catch(error){
        console.log('202105111238',error);
    }
    }

    getRadarLayers = async () => {
        this.radar_layers = [];
        try {
            for (let radarFolder of this.radar_folders) {
                let new_radar_layer_url = this.home_url + 'cloud1/cloud/' + radarFolder.folder + "/{z}/{x}/{y}.png";
                let new_radar_layer = window.L.tileLayer(new_radar_layer_url, { tms: true, maxZoom: 18, maxNativeZoom: 8, minZoom: 2, zIndex: 5 });
                let single_layer_object = {};
                let time_str = radarFolder.datetime;
                single_layer_object['id'] = radarFolder.folder;
                single_layer_object['datetime'] = moment.utc(time_str, "YYMMDDhhmm").local().format('LLLL');
                single_layer_object['datetime_en'] = moment.utc(time_str, "YYMMDDhhmm")._d.toLocaleDateString('en-GB',{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'});
                single_layer_object['standard_date'] = moment.utc(time_str, "YYMMDDhhmm");
                single_layer_object['layer'] = new_radar_layer;
                this.radar_layers.push(single_layer_object);
            }
        } catch (error) { console.log('202010011256', error); }
    }

    setRadarLayers = async () => {
        this.setState({ paused: false, stopped: false, sliderMoved: false }, () => { });
        this.stop_sliding = false;
        this.setState({ slider_max: parseInt(this.radar_folders.length) - 1 }, () => { });

        if (this.last_layer) {
            this.map_object.removeLayer(this.last_layer);
        }

        for (let index = 0; index < this.radar_layers.length; index++) {
            if (this.mapChanged === true) {
                break;
            }
            if (this.refStopped === true) {
                let x = parseInt(index);
                if (x > -1) {
                    this.map_object.removeLayer(this.radar_layers[x].layer);
                }
                this.refStopped = false;
                break;
            }

            if (this.stop_sliding === false) {
                try {
                    this.setState({ current_layer: this.radar_layers[parseInt(index)] }, () => { });
                } catch (message) { console.log('202010160847', message); }
                if (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) {
                    try {
                        let x = parseInt(index) + 1;
                        if (this.radar_layers[x]) {
                            this.map_object.removeLayer(this.radar_layers[x].layer);
                            this.radar_layers[x].layer.setOpacity(0);
                            this.radar_layers[x].layer.addTo(this.map_object);
                            await this.setMapLoaded(this.radar_layers[x].layer);
                            //this function check weather the current tile layer is completely loaded.
                            if (this.refStopped === true) {
                                this.refStopped = false;
                                break;
                            }
                        }
                    } catch (message) { console.log('202010011154', message); }
                }
                else {
                    await this.Sleep(500);
                }

                try {
                    this.radar_layers[parseInt(index)].layer.setOpacity(.7);
                    let x = parseInt(index) - 1;
                    if (x > -1) {
                        this.map_object.removeLayer(this.radar_layers[x].layer);
                    }
                    if (index === 0) {
                        this.radar_layers[parseInt(index)].layer.addTo(this.map_object);
                        await this.setMapLoaded(this.radar_layers[parseInt(index)].layer);
                    }
                    this.setState({ slider_value: this.radar_layers[parseInt(index)].id }, () => { });
                    this.last_layer = this.radar_layers[parseInt(index)].layer;
                    await this.Sleep(1000);
                } catch (error) {
                    console.log('202010160848', error);
                }
                this.enableMapMove();
            }
        }
    }

    setRadarLayersPlay = async (startIndex) => {
        let valStartIndex = parseInt(startIndex) + 1;
        this.setState({ paused: false, stopped: false }, () => { })
        this.stop_sliding = false;
        this.setState({ slider_max: parseInt(this.radar_folders.length) - 1 }, () => { });
        if (this.last_layer) {
            this.map_object.removeLayer(this.last_layer);
        }
        for (let index = valStartIndex; index < this.radar_layers.length; index++) {
            if (this.mapChanged === true) {
                break;
            }
            if (this.stop_sliding === false) {
                try {
                    this.setState({ current_layer: this.radar_layers[parseInt(index)] }, () => { });
                } catch (message) { console.log('202010160849', message); }

                if (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) {
                    try {
                        let x = parseInt(index) + 1;
                        if (this.radar_layers[x]) {
                            this.map_object.removeLayer(this.radar_layers[x].layer);
                            this.radar_layers[x].layer.setOpacity(0);
                            this.radar_layers[x].layer.addTo(this.map_object);
                            await this.setMapLoaded(this.radar_layers[x].layer);
                            //this function check weather the current tile layer is completely loaded.
                        }
                    } catch (message) { console.log('202010160850', message); }
                }
                else {
                    await this.Sleep(500);
                }
                try {
                    let x = parseInt(index) - 1;
                    if (x > -1) {
                        this.map_object.removeLayer(this.radar_layers[x].layer);
                    }
                    this.radar_layers[parseInt(index)].layer.setOpacity(.7);
                    this.radar_layers[parseInt(index)].layer.addTo(this.map_object);
                    this.setState({ slider_value: this.radar_layers[parseInt(index)].id }, () => { });
                    this.last_layer = this.radar_layers[parseInt(index)].layer;
                    await this.Sleep(1000);
                } catch (error) {
                    console.log('202010160851', error);
                }
                this.enableMapMove();
            }
        }
    }

    setMapLoaded = async (argLayer) => {
        try{
        return new Promise((resolve, reject) => {
            if (this.refStopped === true) {
                resolve(argLayer);
            } else {
                argLayer.on("load", async function () {
                    resolve(argLayer);
                });
            }
        });
    }catch(error){
        console.log('202103231447',error);
    }
    }

    Sleep = (ms) => {
        try{
        return new Promise(resolve => setTimeout(resolve, ms));
    }catch(error){console.log('202105111239',error);}
    }

    /*selectTimestep = async () => {
        console.log('-----selectTimestep-----');
        try {
            this.stop_sliding = true;
            //console.log('slider value -- 1 : ', this.state.slider_value);
            this.setState({ current_layer: this.radar_layers[parseInt(this.state.slider_value)] }, () => {});
            this.radar_layers[parseInt(this.state.slider_value)].layer.setOpacity(.7);
            this.radar_layers[parseInt(this.state.slider_value)].layer.addTo(this.map_object);
            if (parseInt(this.state.slider_value) !== parseInt(this.state.current_layer.id)) {
                this.map_object.removeLayer(this.last_layer);
            }
            this.last_layer = this.radar_layers[parseInt(this.state.slider_value)].layer;
            this.enableMapMove();
            //console.log(this.radar_layers);
            //console.log(this.radar_layers[parseInt(this.state.slider_value)]);
            //console.log('slider max : ', this.state.slider_max);
            //console.log('current layer : ', this.state.current_layer.id);
            //console.log('current layer time : ', this.state.current_layer.datetime);

        } catch (error) {
            console.log('202010060241', error);
        }
    }*/

    selectTimestep = async (varSlideValue) => {
        try {
            this.stop_sliding = true;
            this.setState({ current_layer: this.radar_layers[parseInt(varSlideValue)] }, () => { });
            this.radar_layers[parseInt(varSlideValue)].layer.setOpacity(.7);
            this.radar_layers[parseInt(varSlideValue)].layer.addTo(this.map_object);
            if (parseInt(varSlideValue) !== parseInt(this.state.current_layer.id)) {
                this.map_object.removeLayer(this.last_layer);
            }
            this.last_layer = this.radar_layers[parseInt(varSlideValue)].layer;
            this.enableMapMove();
        } catch (error) {
            console.log('202010060241', error);
        }
    }

    enableMapMove = () => {
        try{
        this.map_object.dragging.enable();
    }catch(error){console.log('202105111240',error);}
    }

    disableMapmove = () => {
        try{
        this.map_object.dragging.disable();
    }catch(error){console.log('202105111241',error);}
    }

    sliderValueHandler = async (event) => {
        try{
        if (this.last_layer) {
            this.map_object.removeLayer(this.last_layer);
        }
        this.setState({ slider_value: event.target.value, stopped: true, sliderMoved: true, pausedSliderValue: event.target.value }, () => { });
        await this.selectTimestep(event.target.value);
    }catch(error){console.log('202105111242',error);}
    };

    sliderMoved = async (event) => {
        try{
        this.setState({ sliderMoved: true, pausedSliderValue: this.state.slider_value }, () => { });
    }catch(error){console.log('202105111243',error);}
    }

    stopSlide = (argVal) => {
        try{
        if (this.stop_sliding === true) {
            this.selectTimestep(argVal);
        }
        else {
            this.stop_sliding = true;
        }
    }catch(error){console.log('202105111244',error);}
    }

    pauseSlideAnimation = async () => {
        try{
        this.setState({ paused: true, pausedSliderValue: this.state.slider_value }, () => { });
        this.stop_sliding = true;
        await this.Sleep(500);
    }catch(error){console.log('202105111245',error);}
    }

    stopSlideAnimation = async () => {
        try {
            this.map_object.removeLayer(this.radar_layers[parseInt(this.state.slider_value)].layer);
            this.map_object.removeLayer(this.radar_layers[parseInt(this.state.slider_value) + 1].layer);
            this.stop_sliding = true;
            this.refStopped = true;
            await this.Sleep(750);
            this.setState({ stopped: true }, () => { });
        } catch (error) { console.log('202010161214', error) }
    }

    playSlideAnimation = async () => {
        try{
        await this.Sleep(500);
        this.setState({ paused: false, sliderMoved: false }, () => { });
        this.stop_sliding = false;
        this.setRadarLayersPlay(this.state.pausedSliderValue);
    }catch(error){console.log('202105111246',error);}
    }

    refreshSlideAnimation = async () => {
        try{
        this.refStopped = false;
        await this.Sleep(500);
        this.setRadarLayers();
        this.setState({ pausedSliderValue: 0 });
    }catch(error){console.log('202105111247',error);}
    }

    handleMoveEnd = () => {
        /*localStorage.setItem('coordsData', JSON.stringify({
            commonLat : Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonLng:  Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonZoom: Number(this.mapRef.current.leafletElement.getZoom())}));*/
            try{
        this.props.updateCoords(
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getZoom())
        );
    }catch(error){console.log('202105111248',error);}
    }

    handleZoomEnd = () => {
        /*localStorage.setItem('coordsData', JSON.stringify({
            commonLat : Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonLng:  Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonZoom: Number(this.mapRef.current.leafletElement.getZoom())}));*/
            try{
        this.props.updateCoords(
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getZoom())
        );
    }catch(error){console.log('202105111249',error);}
    }


    render() {
        const position = [this.state.lng, this.state.lat];
        let userDate = new Date();
        let layerDate = new Date(this.state.current_layer.standard_date);
        const markerPosition = [this.props.userLat, this.props.userLng];

        return (
            <div>
                <div className={cssModule.MapControlModule}>
                    {
                        (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) ?
                            <div className={cssModule.ControlTextContainer}>
                                <span className={cssModule.ControlText}>{layerDate <= userDate ? (this.state.language === 'en' ? 'Past' : 'Vergangenheit') : null}</span>
                                <span className={cssModule.ControlText}>{layerDate > userDate ? (this.state.language === 'en' ? 'Forecast' : 'Vorhersage') : null}</span>
                                <span className={cssModule.ControlTextTime}>{this.state.language === 'en' ? this.state.current_layer.datetime_en : this.state.current_layer.datetime}</span>
                            </div>
                            : null
                    }
                    <div className={cssModule.ControlButtonContainer}>
                        <div className={cssModule.SliderControl}>
                            <input type='range'
                                id='IdSlider'
                                className={cssModule.SliderSelector}
                                min={0}
                                max={this.state.slider_max}
                                value={this.state.slider_value}
                                onInput={this.sliderMoved}
                                onChange={this.sliderValueHandler}
                                onMouseDown={this.disableMapmove}
                                onMouseMove={this.disableMapmove}
                                onMouseUp={this.enableMapMove}
                                onMouseOut={this.enableMapMove}
                                />
                        </div>
                        <div className={cssModule.ButtonIcons}>
                            {
                                ((this.state.paused && !this.state.stopped) || this.state.sliderMoved) ?
                                    <span style={{cursor: 'pointer'}}>
                                        <PlayArrowIcon fontSize='large' onClick={this.playSlideAnimation} color={'#000'} />
                                    </span> : null
                            }
                            {
                                ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.paused && !this.state.stopped) ?
                                    <span style={{cursor: 'pointer'}}>
                                        <PauseIcon fontSize='large' onClick={this.pauseSlideAnimation} color={'#000'} />
                                    </span>
                                    : null
                            }
                            {
                                ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.stopped) ?
                                    <span style={{cursor: 'pointer'}}>
                                        <StopIcon fontSize='large' onClick={this.stopSlideAnimation} color={'#000'} />
                                    </span> : null
                            }
                            {
                                ((parseInt(this.state.current_layer.id) === parseInt(this.state.slider_max)) || this.state.stopped) ?
                                    <span style={{cursor: 'pointer'}}>
                                        <RefreshOutlinedIcon fontSize='large' onClick={this.refreshSlideAnimation} color={'#000'} />
                                    </span>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                {/*<div className={cssModule.DetailsModule}>
                    <div className={cssModule.SliderModule}>
                        <div className={cssModule.DateModule}>
                            {
                                (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) ?
                                    <span className={cssModule.MapTime}>
                                        <div className={cssModule.TextBold}>{layerDate <= userDate ? 'Vergangenheit' : null}</div>
                                        <div className={cssModule.TextBold}>{layerDate > userDate ? 'Vorhersage' : null}</div>
                                    </span>
                                    : null
                            }
                            {
                                (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) ?
                                    <div className={cssModule.TimeDisplay}>{this.state.current_layer.datetime}</div>
                                    : null
                            }
                        </div>

                        <input type='range'
                            id='IdSlider'
                            className={cssModule.SliderSelector}
                            min={0}
                            max={this.state.slider_max}
                            value={this.state.slider_value}
                            onInput={this.sliderMoved}
                            onChange={this.sliderValueHandler}
                            onMouseDown={this.disableMapmove}
                            onMouseMove={this.disableMapmove}
                        />

                    </div>
                    <div className={cssModule.ButtonIcons}>
                        {
                            ((this.state.paused && !this.state.stopped) || this.state.sliderMoved) ?
                                <span>
                                    <PlayArrowIcon fontSize='large' onClick={this.playSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span> : null
                        }
                        {
                            ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.paused && !this.state.stopped) ?
                                <span>
                                    <PauseIcon fontSize='large' onClick={this.pauseSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span>
                                : null
                        }
                        {
                            ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.stopped) ?
                                <span>
                                    <StopIcon fontSize='large' onClick={this.stopSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span> : null
                        }

                        {
                            ((parseInt(this.state.current_layer.id) === parseInt(this.state.slider_max)) || this.state.stopped) ?
                                <span>
                                    <RefreshOutlinedIcon fontSize='large' onClick={this.refreshSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span>
                                : null
                        }
                    </div>
                </div>*/}

                <Map
                    ref={this.mapRef}
                    style={{ height: '100vh' }}
                    center={position}
                    zoom={parseInt(this.state.zoom) < 2 ? 2 : this.state.zoom}
                    zoomControl={true}
                    minZoom={2}
                    maxZoom={10}
                    onmoveend={this.handleMoveEnd}
                    onzoomend={this.handleZoomEnd}
                    attributionControl={false}>
                    <TileLayer
                        url="https://radar.qmet.de/tileLayer/{z}/{x}/{y}.png"
                        zIndex='1'
                        attributionControl={false}
                        attribution='<a target="_blank" rel="nofollow noopener" href="https://leafletjs.com/">Leaflet</a> | &copy; <a target="_blank" rel="nofollow noopener" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                    <AttributionControl position="bottomright" prefix={false} />
                    <Marker position={markerPosition} draggable={false}></Marker>
                </Map>
            </div>
        );
    }
}

export default mapComponent;
