import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
//import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@mdi/react';
import { mdiWeatherCloudy, mdiWeatherPouring, mdiTemperatureCelsius, mdiRadar } from '@mdi/js';

import MapRadarComponent from '../mapRadarComponenet/mapRadarComponent';
import MapWolkenComponent from '../mapWolkenComponenet/mapWolkenComponent';
import MapTempComponent from '../mapTempComponenet/mapTempComponent';
import MapRegenComponent from '../mapRegenComponenet/mapRegenComponent';
//import MapTest from '../mapTest/mapTest';

import cssModule from './mapContainer.module.css';

const maps = ['Radar', 'Wolken', 'Temperatur', 'Regenprognose'];

const useStyles = makeStyles(theme => ({
    maptoggler: {
        '&:hover':{
            backgroundColor: '#FFE857',
            color: '#000',
        },
        backgroundColor: '#FFE857',
        color: '#000',
        fontWeight: 'bold',
        borderRadius: '5px',
        textTransform: 'none',
        fontSize: '.9rem'
    },
    dialogPaper: {
        minHeight: 'auto',
        borderRadius: '10px',
        fontWeight:'500',
        maxHeight: '100%',
        minWidth: '400px',
        '@media (max-width: 500px)': {
            maxHeight: '100%',
            minWidth: '90vw',
        },
    },
    modalHead:{
        paddingTop: '10px',
        paddingBottom: '5px',
        paddingLeft: '7px',
        paddingRight: '7px',
        '& h2':{lineHeight:'25px'}
    }
}));

function SimpleDialog(props) {

    const customClasses = useStyles();
    const { onClose, selectedValue, open, language } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const getIcons = (argMap) => {
        if (argMap === 'Radar') {
            return (<Icon path={mdiRadar} size={1} color={'#FFE857'} />);
        } else if (argMap === 'Wolken') {
            return (<Icon path={mdiWeatherCloudy} size={1} color={'#FFE857'} />);
        } else if (argMap === 'Temperatur') {
            return (<Icon path={mdiTemperatureCelsius} size={1} color={'#FFE857'} />);
        } else {
            return (<Icon path={mdiWeatherPouring} size={1} color={'#FFE857'} />);
        }
    }

    const getTranslatedMap = (argMap) => {
        try{
            if (argMap === 'Radar') {
                if(language.toString()=== 'en'){
                    return ('Radar');
                }else{
                    return ('Radar');
                }
            } else if (argMap === 'Wolken') {
                if(language.toString()=== 'en'){
                    return ('Clouds');
                }else{
                    return ('Wolken');
                }
            } else if (argMap === 'Temperatur') {
                if(language.toString()=== 'en'){
                    return ('Temperature');
                }else{
                    return ('Temperatur');
                }
            } else {
                if(language.toString()=== 'en'){
                    return ('Rain forecast');
                }else{
                    return ('Regenprognose');
                }
            }
        }catch(error){}
    }

    return (
        <Dialog className={cssModule.MapDialog} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} classes={{ paper: customClasses.dialogPaper }}>
            <DialogTitle id="simple-dialog-title" classes={{ root: customClasses.modalHead }}>{language.toString() === 'en' ? 'Select the card type' : 'Wählen Sie den Kartentyp' }</DialogTitle>
            <List>
                {maps.map((map) => (
                    <ListItem button onClick={() => handleListItemClick(map)} key={map}>
                        <span style={{paddingRight:'10px'}}>
                                {
                                    getIcons(map)
                                }
                        </span>
                        <ListItemText primary={getTranslatedMap(map)} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

let tempLat;
let tempLng;
let tempZoom;

const MapContainer = () => {

    const customClasses = useStyles();

    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [hookLat, setHookLat] = useState(0);
    const [hookLon, setHookLon] = useState(0);
    const [hookZoom, setHookZoom] = useState(8);
    const [hookUnit, setUnit] = useState('metric');
    const [hookLanguage, setLanguage] = useState('de');
    const [hookShowToggler, setShowToggler] = useState(true);

    /* sample URL ?lat=50.66&lon=7.42&zoom=8&units=metric&language=de&toggler=true*/

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const userLat = params.get('lat') ? !isNaN(params.get('lat')) ? Number(params.get('lat').toString().trim()) : 50.078217 : 50.078217;
    const userLng = params.get('lon') ? !isNaN(params.get('lon')) ? Number(params.get('lon').toString().trim()) : 8.23976 : 8.23976;

    useEffect(() => {

        setHookLat(params.get('lat') ? !isNaN(params.get('lat')) ? params.get('lat') : 50.078217 : 50.078217);
        setHookLon(params.get('lon') ? !isNaN(params.get('lon')) ? params.get('lon') : 8.239761 : 8.239761);

        if(params.get('units') && params.get('units').toString() === "metric"){
            setUnit("metric");
        }else if(params.get('units') && params.get('units').toString() === "imperial"){
            setUnit("imperial");
        }else{
            setUnit("metric");
        }

        if(params.get('layer') && params.get('layer').toString() === 'radar'){
            setSelectedValue(maps[0]);//'Radar', 'Wolken', 'Temperatur', 'Regenprognose'
        }else if(params.get('layer') && params.get('layer').toString() === 'cloud'){
            setSelectedValue(maps[1]);
        }else if(params.get('layer') && params.get('layer').toString() === 'temp'){
            setSelectedValue(maps[2]);
        }else if(params.get('layer') && params.get('layer').toString() === 'precip'){
            setSelectedValue(maps[3]);
        }else{
            setSelectedValue(maps[0]);
        }

        if(params.get('language') && params.get('language').toString() === "de"){
            setLanguage("de");
        }else if(params.get('language') && params.get('language').toString() === "en"){
            setLanguage("en");
        }else{
            setLanguage("de");
        }

        if(params.get('toggler') && params.get('toggler').toString() === 'true'){
            setShowToggler(true);
        }else if(params.get('toggler') && params.get('toggler').toString() === 'false'){
            setShowToggler(false);
        }else{
            setShowToggler(true);
        }

        if(params.get('zoom') && !isNaN(params.get('zoom'))){
            setHookZoom(Number(params.get('zoom')));
        }else{
            setHookZoom(8);
        }

        /*localStorage.setItem('coordsData', JSON.stringify(
            {
                commonLat: params.get('lat') ? Number(params.get('lat').toString().trim()) : 50.078217,
                commonLng: params.get('lon') ? Number(params.get('lon').toString().trim()) : 8.23976,
                commonZoom: 10
            }));*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOpen = async () => {
        setOpen(true);
    };

    const updateCoords = (argLat, argLon,argZoom) => {
        //setHookLat(argLat);
        //setHookLon(argLon);
        //setHookZoom(argZoom);
        tempLat= argLat;
        tempLng=argLon;
        tempZoom= argZoom;
        //console.log('parent coords', tempLat, tempLng, tempZoom);
    }

    const getMapName = (argMapName) => {
        try{
            if( argMapName && argMapName === 'Regenprognose' ){
                if( hookLanguage ===  'en'){
                    return ('Rain forecast');
                }else{
                    return ('Regenprognose');
                }
            }else if(argMapName && argMapName === 'Temperatur'){
                if( hookLanguage ===  'en'){
                    return ('Temperature');
                }else{
                    return ('Temperatur');
                }
            }else if(argMapName && argMapName === 'Wolken'){
                if( hookLanguage ===  'en'){
                    return ('Clouds');
                }else{
                    return ('Wolken');
                }
            }else{
                if( hookLanguage ===  'en'){
                    return ('German radar');
                }else{
                    return ('Deutschlandradar');
                }
            }
        }catch(error){}
    }

    const handleClose = (value) => {
        //let retrievedObject = localStorage.getItem('coordsData');
        //retrievedObject = JSON.parse(retrievedObject);
        //console.log('parent coords on change', tempLat, tempLng, tempZoom);
        /*const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if(value.toString()==="Radar"){
            params.set('layer','radar');
        }else if(value.toString()==="Wolken"){
            params.set('layer','cloud');
        }else if(value.toString()==="Temperatur"){
            params.set('layer','temp');
        }else if(value.toString()==="Regenprognose"){
            params.set('layer','precip');
        }else{
            params.set('layer','radar');
        }
        window.location.search = params;*/

        if(!isNaN(tempLat)){
            setHookLat(tempLat);
        }

        if(!isNaN(tempLng)){
            setHookLon(tempLng);
        }
        if(!isNaN(tempZoom)){
            setHookZoom(tempZoom);
        }

        setOpen(false);
        setSelectedValue(value);
    };

    let componentOrganizer = null;

    if (selectedValue === 'Radar') {
        componentOrganizer = (<MapRadarComponent
            propsLon={hookLon}
            propsLat={hookLat}
            propsZoom={hookZoom}
            userLat={userLat}
            userLng={userLng}
            kartentyp={selectedValue}
            mapChanged={true}
            language = {hookLanguage}
            updateCoords={updateCoords} />);
        {/*componentOrganizer = (<MapTest
            propsLon={hookLon}
            propsLat={hookLat}
            propsZoom={hookZoom}
            userLat={userLat}
            userLng={userLng}
            kartentyp={selectedValue}
            mapChanged={true}
            language = {hookLanguage}
            updateCoords={updateCoords} />);*/}
    } else if (selectedValue === 'Wolken') {
        componentOrganizer = (<MapWolkenComponent
            propsLon={hookLon}
            propsLat={hookLat}
            propsZoom={hookZoom}
            userLat={userLat}
            userLng={userLng}
            kartentyp={selectedValue}
            mapChanged={true}
            language = {hookLanguage}
            updateCoords={updateCoords} />);
    } else if (selectedValue === 'Temperatur') {
        componentOrganizer = (<MapTempComponent
            propsLon={hookLon}
            propsLat={hookLat}
            propsZoom={hookZoom}
            userLat={userLat}
            userLng={userLng}
            kartentyp={selectedValue}
            mapChanged={true}
            language = {hookLanguage}
            updateCoords={updateCoords}
            unit={hookUnit} />);
    } else if (selectedValue === 'Regenprognose') {
        componentOrganizer = (<MapRegenComponent
            propsLon={hookLon}
            propsLat={hookLat}
            propsZoom={hookZoom}
            userLat={userLat}
            userLng={userLng}
            kartentyp={selectedValue}
            mapChanged={true}
            language = {hookLanguage}
            updateCoords={updateCoords} />);
    }

    return (<React.Fragment>
        <div className={cssModule.MapSelector}>
            <div className={cssModule.MapSelectorSub}>
                { hookShowToggler ?(<Button classes={{ root: customClasses.maptoggler }} variant="contained" onClick={handleClickOpen} size="small" endIcon={<ArrowDropDownIcon />}>
                    <span style={{ textTransform: 'none' }}>{
                        getMapName(selectedValue)
                        /*selectedValue === 'Regenprognose' ? 'Regenprognose' :
                            selectedValue === 'Temperatur' ? 'Temperatur' :
                                selectedValue === 'Wolken' ? 'Wolken' :
                                    `Deutschland${selectedValue.toLowerCase()}`*/
                    }</span>
                </Button>) : null}
            </div>
        </div>
        <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose}  language = {hookLanguage}/>
        {componentOrganizer}
    </React.Fragment>);
}

export default MapContainer;